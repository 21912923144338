import { camelCase } from 'lodash';
import { useCart } from '@/hooks/CartContext';
import { useCallback, useState } from 'react';
import { sem_imagem as semImg } from '@/constants';
import { getOnStorage, setOnStorage } from '@/shared';
import { useClient, useFetchAxios, useGlobals, useUserContext } from '@/hooks';
import { useDbFunctions, Tables, toCoded, TableTypes } from '../dbFunctions/useDBFunctions';
import { ProductProps, CategoryProps, DataSetsProps, CustomerDataProps, PromocaoClientProps, CampanhaProdutosProps } from '@/types';

type CatalogDictProps = Record<number, ProductProps>;
type CategoryDictProps = Record<number, CategoryProps>;

const updateProgress = (loaded: number, total: number, name: string) => {
  const percentual = (loaded / total) * 100;
  const progressBar = document.getElementById(`${name}-bar`) as HTMLElement;
  const progressText = document.getElementById(`${name}-text`) as HTMLElement;
  const isInfinity = percentual !== Infinity;

  if (progressBar) progressBar.style.width = `${percentual}%`;
  if (progressText) {
    progressText.textContent = isInfinity ? `${Math.round(percentual)}%` : 'Erro no Download';
  }
};

export const useDownloadFiles = () => {
  const { axiosCtx } = useFetchAxios();
  const { setCustomer } = useUserContext();
  const { setHasLoadedEstoque } = useCart();
  const [hasError, setError] = useState(false);
  const { setLastSync, getDate } = useGlobals();
  const [loading, setLoading] = useState(false);
  const { setOnDB, getOnDB } = useDbFunctions();
  const [preparing, setPreparing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileName, setFileDownloading] = useState('');
  const [isOpenedModal, openModalSync] = useState(false);
  const [noUpdate, setNoUpdate] = useState(false as Boolean);
  const [datasets, setDataSets] = useState([] as DataSetsProps[]);
  const { setCampanhaProdutos, setCampanhaClientes } = useClient();

  const setFileName = useCallback(() => {
    if (isSuccess) return;

    const listDataBase = getOnStorage('datasets');
    const ind = Math.floor(Math.random() * (listDataBase?.length || 0));
    let label = listDataBase?.[ind]?.DESC_ARQUIVO;
    if (label === fileName) {
      label = listDataBase?.[ind + 1]?.DESC_ARQUIVO || 'Baixando';
    }

    setFileDownloading(label || '');
    setTimeout(() => setFileName(), 3000);
  }, [isSuccess, fileName]);

  const mountListToDownload = useCallback(async (newDatasets?: DataSetsProps[]) => {
    let listToLoad = {} as Record<string, DataSetsProps>
    const listDataBase = newDatasets || getOnStorage('new_data_sets') || [];
    const actualDataBase = getOnStorage('datasets') || [];

    if (!actualDataBase) {
      listToLoad = listDataBase.reduce((acc, ldb) => {
        acc[ldb.TIPO_ARQUIVO] = ldb
        return acc
      }, {} as Record<string, DataSetsProps>);
    } else {
      for (const element of listDataBase || []) {
        if (!actualDataBase?.find((i) => i.URL_S3 === element.URL_S3)) {
          listToLoad[element.TIPO_ARQUIVO] = element
        }
      }

      for (const element of actualDataBase || []) {
        const fileType = camelCase(element.TIPO_ARQUIVO)

        const modifyers: Record<string, string> = {
          preco: 'precos',
          marcas: 'marcas',
          estoque: 'estoque',
          imagens: 'imagens',
          cliente: 'clientes',
          catalogo: 'catalogo',
          categoria: 'categorias',
          janela_entrega: 'janelaEntrega',
          parametros_aws: 'parametrosAws',
          parametro_preco_base: 'parametroPrecoBase',
          codRegimeTributario: 'substituicaotributaria',
          percentual_fornecedor: 'percentualFornecedor',
          cod_regime_tributario: 'substituicaotributaria',
          restricaoProdutoCliente: 'productRestrictionClient',
        };

        const table = modifyers[fileType];
        const item = table ? await getOnDB(table as Tables) : null

        const isFill = Array.isArray(item) ? !!item?.length : !!Object.keys(item || {})?.length

        if (!isFill && table) {
          listToLoad[element.TIPO_ARQUIVO] = element
        }
      }
    }

    if (!listToLoad) {
      setNoUpdate(true);
    };

    const listToDownload = Object.values(listToLoad)

    const mergedList = [...actualDataBase, ...listToDownload].reduce((acc, i) => {
      acc[i.TIPO_ARQUIVO] = i
      return acc
    }, {} as Record<string, DataSetsProps>)

    setDataSets(listToDownload);
    setOnStorage('datasets', Object.values(mergedList));
    setOnStorage('new_data_sets', Object.values(mergedList));

    return listToDownload
  }, [getOnDB])

  const fetchDatasets = useCallback(async (list?: DataSetsProps[]) => {
    var totalSize = 0;
    var downloadedSize = 0;

    const fetchAndSaveJson = async (url: string, name: string, enableLog?: boolean): Promise<any> => {
      return new Promise(async (resolve) => {
        await fetch(url)
          .then((resp: any) => {
            if (resp.status >= 300 || resp.status < 200) throw new Error('');

            var totalArchive = 0;
            var downloadedArchive = 0;

            totalSize += Number(resp.headers.get('content-length'));
            totalArchive += Number(resp.headers.get('content-length'));

            let chunks: any[] = [];
            const reader = resp.body.getReader();
            const decoder = new TextDecoder('utf-8');

            const readChunk = () => {
              reader
                .read()
                .then(async ({ done, value }: any) => {
                  try {
                    if (done) {
                      const json = JSON.parse(chunks.join(''));
                      let data = json?.catalogo || json?.preco || json;

                      if (name === 'imagens') {
                        data = typeof data === 'string' ? JSON.parse(data) : data
                        data = data?.reduce(
                          (acc: any, item: any) => ({
                            ...acc,
                            [item.COD_PRODUTO]: item.imagem,
                          }),
                          {}
                        );
                      }

                      if (typeof data === 'string') data = JSON.parse(data);
                      resolve({ data, name, enableLog });
                    }

                    downloadedSize += value?.byteLength || 1;
                    downloadedArchive += value?.byteLength || 1;
                    updateProgress(downloadedArchive, totalArchive, name);
                    updateProgress(downloadedSize, totalSize, 'progress');
                    if (value?.byteLength) {
                      chunks.push(decoder.decode(value, { stream: true }));
                      readChunk();
                    }
                  } catch (error) {
                    console.log('Erro durante a leitura do stream:', error);
                  }
                })
                .catch((error: any) => {
                  setError(true);
                  console.error('Erro no download do stream:', error);
                });
            };

            readChunk();
          })
          .catch((error) => {
            if (!['precoPromocaoCliente', 'indicadores', 'recomendacaoBigData'].includes(name)) {
              setError(true);
            }
            console.error('Erro durante o download:', error);
            return resolve(true)
          });
      });
    };

    const links = list?.map(({ TIPO_ARQUIVO, URL_S3 }) => fetchAndSaveJson(URL_S3, TIPO_ARQUIVO, true)) || [];

    const response = await Promise.all(links)
      .then((res) => res.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}))
      .catch(() => { });

    return response
  }, []);

  const loadFiles = useCallback(
    async (newDatasets?: DataSetsProps[]) => {
      if (loading) return;

      setFileName();
      setLoading(true);
      setError(false);
      setIsSuccess(false);
      updateProgress(0, 0, '.');

      const list = await mountListToDownload(newDatasets)
      const resp = await fetchDatasets(list)

      setPreparing(true);

      const getData = async <T extends Tables>(dataKey: string, dbKey: T, defaultValue: any): Promise<TableTypes[T]> => {
        return resp?.[dataKey]?.data || await getOnDB(dbKey) || defaultValue;
      }

      const precos = await getData('preco', 'precos', []);
      const marcas = await getData('marcas', 'marcas', []);
      const imagens = await getData('imagens', 'imagens', {});
      const estoque = await getData('estoque', 'estoque', []);
      const catalogo = await getData('catalogo', 'catalogo', []);
      const loadedClients= await getData('cliente', 'clientes',[]);
      const categorias= await getData('categoria', 'categorias',[]);
      const saldoFlex = await getData('saldo_flex', 'saldoFlex', []);
      const indicadores = await getData('indicadores', 'indicadores', []);
      const campanha = await getData('campanha_indicador', 'campanha', []);
      const listPageBuilder = await getData('pagebuilder', 'pagebuilder', []);
      const janelaEntrega = await getData('janela_entrega', 'janelaEntrega', []);
      const parametrosAws = await getData('parametros_aws', 'parametrosAws', []);
      const campanhaFaixa = await getData('campanha_faixa', 'campanha_faixa', []);
      const campanhaProduto = await getData('campanha_produto', 'campanhaProduto',{});
      const campanhaCliente = await getData('campanha_cliente', 'campanhaCliente',{});
      const campanhaTopMix = await getData('campanha_mix_fornecedor', 'campanha_mix', []);
      const parametroPrecoBase = await getData('parametro_preco_base', 'parametroPrecoBase', []);
      const recomendacaoBigData = await getData('recomendacaoBigData', 'recomendacaoBigData', []);
      const precoPromocaoCliente = await getData('precoPromocaoCliente', 'precoPromocaoCliente', []);
      const cicloVendaCliente= await getData('ciclo_venda_cliente_cliente', 'cicloVendaCliente', []);
      const percentualFornecedor = await getData('percentual_fornecedor', 'percentualFornecedor', []);
      const substituicaotributaria = await getData('cod_regime_tributario', 'substituicaotributaria', []);
      const restricaoProdutoCliente = await getData('restricaoProdutoCliente', 'productRestrictionClient', []);

      const objProduct = {} as CatalogDictProps;
      const objCategory = {} as CategoryDictProps;

      const parseJSON = ((item: any) => {
        try {
          return JSON.parse(item)
        } catch (error) {
          return item
        }
      })

      const clientes = loadedClients.sort((a, b) => {
        if (a.NM_FANTASIA > b.NM_FANTASIA) return 1
        if (a.NM_FANTASIA < b.NM_FANTASIA) return -1
        return 0
      }).map(c => {
        c.COD_SEGMENTO = parseJSON(c?.COD_SEGMENTO || '[]')
        c.DT_ULTIMA_COMPRA = parseJSON(c?.DT_ULTIMA_COMPRA || '[]')
        c.UNILEVER = c.FL_UNILEVER === 'True'
        const CYCLE = (cicloVendaCliente.filter(cycle => c.COD_CLIENTE === cycle.COD_CLIENTE) || []).sort((a, b) => a.SEMANA_CICLO_ID - b.SEMANA_CICLO_ID)

        return { ...c, CYCLE }
      }) || [];

      const promocaoCliente = Array.isArray(precoPromocaoCliente) ? precoPromocaoCliente.reduce((acc: any, p: PromocaoClientProps) => {
        if (acc[p.COD_CLIENTE]) {
          acc[p.COD_CLIENTE][p.COD_PRODUTO] = p
        } else {
          acc[p.COD_CLIENTE] = { [p.COD_PRODUTO]: p }
        }
        return acc
      }, {}) : precoPromocaoCliente;

      const products = catalogo
        .map((item: ProductProps) => {
          const PRECO = precos?.filter((i: any) => `${i.COD_PRODUTO}` === `${item.COD_PRODUTO}`);

          const EMBALAGEM = item?.EMBALAGEM?.map((i) => {
            if (i?.QTD_MULTIPLO_VENDA > 1) {
              return { ...i, SG_EMBALAGEM: "PK" }
            }

            return { ...i }
          })

          const IMAGEM = imagens?.[item?.COD_PRODUTO] || imagens['sem_imagem'] || semImg;

          return { ...item, PRECO, IMAGEM, EMBALAGEM, UNILEVER: item.FL_UNILEVER === 'True' };
        })
        ?.filter((item: any) => !!item?.PRECO?.length);

      if (categorias?.length > 0 && products?.length > 0) {
        categorias?.forEach((item: CategoryProps) => {
          if (!objCategory[item?.COD_CATEGORIA]) {
            objCategory[item?.COD_CATEGORIA] = {
              ...item,
            };
          }
        });

        products?.forEach((item: ProductProps) => {
          if (!objProduct[item?.COD_PRODUTO]) {
            objProduct[item?.COD_PRODUTO] = {
              ...item,
            };
          }
        });
      }

      const mountCategories = (campanha: CampanhaProdutosProps) => {
        if (!campanha) return []
        
        return [{
          NRO_HIERARQUIA: 1,
          DE_CATEGORIA: campanha.nome, 
          IMAGE: campanha?.ativo?.selo || '',
          PATH_CATEGORIA: campanha.url_amigavel,
        }] as CategoryProps[]
      }

      const allCategories = [...mountCategories(campanhaProduto), ...categorias];

      const pageBuilder = listPageBuilder.map((i: any, ind: any) => ({ ...i, ID: toCoded(`${ind + 1}`, true, true) }))

      await setOnDB(marcas, 'marcas');
      await setOnDB(precos, 'precos');
      await setOnDB(estoque, 'estoque');
      await setOnDB(imagens, 'imagens');
      await setOnDB(catalogo, 'catalogo');
      await setOnDB(clientes, 'clientes');
      await setOnDB(products, 'produtos');
      await setOnDB(campanha, 'campanha');
      await setOnDB(saldoFlex, 'saldoFlex');
      await setOnDB(pageBuilder, 'pagebuilder');
      await setOnDB(indicadores, 'indicadores');
      await setOnDB(allCategories, 'categorias');
      await setOnDB(parametrosAws, 'parametrosAws');
      await setOnDB(janelaEntrega, 'janelaEntrega');
      await setOnDB(campanhaTopMix, 'campanha_mix');
      await setOnDB(campanhaFaixa, 'campanha_faixa');
      await setOnDB(objProduct, 'dicionario_produtos');
      await setOnDB(campanhaCliente, 'campanhaCliente');
      await setOnDB(campanhaProduto, 'campanhaProduto');
      await setOnDB(objCategory, 'dicionario_categorias');
      await setOnDB(promocaoCliente, 'precoPromocaoCliente');
      await setOnDB(parametroPrecoBase, 'parametroPrecoBase');
      await setOnDB(recomendacaoBigData, 'recomendacaoBigData');
      await setOnDB(percentualFornecedor, 'percentualFornecedor');
      await setOnDB(substituicaotributaria, 'substituicaotributaria');
      await setOnDB(restricaoProdutoCliente, 'productRestrictionClient');

      setPreparing(false);
      setIsSuccess(true);
      setLoading(false);

      const rcaCustomer = getOnStorage('rca_usuario');

      setCustomer(rcaCustomer as CustomerDataProps);
      setCampanhaProdutos(campanhaProduto || {})
      setCampanhaClientes(campanhaCliente || {})
      setHasLoadedEstoque(false);
      return resp
    },
    [loading, setFileName, setOnDB, setCustomer, fetchDatasets, setHasLoadedEstoque, getOnDB, mountListToDownload, setCampanhaProdutos, setCampanhaClientes]
  );

  const getLinks = useCallback(async () => {
    setPreparing(true);
    setError(false);
    setIsSuccess(false);
    setLoading(true);

    const rcaInfos = getOnStorage('rca_usuario');

    const resp: any = await axiosCtx.get(`/getdataset/${rcaInfos?.codPessoa}`).catch(() => 'error');

    if (resp === 'error') {
      setPreparing(false);
      setError(true);
      setLoading(false);
      return;
    }

    const [representante] = resp?.data?.representante || [];
    const list = representante?.link_Dataset || [];
    const oldList = getOnStorage('datasets') || []
    const oldListMaped = oldList.map(i => i.URL_S3)
    const filteredList = list.filter((dt: any) => !oldListMaped.includes(dt.URL_S3));

    const data = await loadFiles(filteredList);
    const formattedDate = getDate();
    setLastSync(formattedDate);

    return data
  }, [loadFiles, setLastSync, getDate, axiosCtx]);

  return {
    loading,
    hasError,
    noUpdate,
    fileName,
    datasets,
    preparing,
    isSuccess,
    isOpenedModal,

    setError,
    loadFiles,
    getLinks,
    openModalSync,
  };
};
