import pacote from "../../package.json";
import { useClient } from "./ClientContext";
import { PaymentConditionsProps } from "@/types";
import { getOnStorage, setOnStorage } from "@/shared";

import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

interface HookProps {
  getDate: any;
  version: string;
  resetAccess: any;
  lastSync: string;
  routeTitle: string;
  paymentCode: number;
  fatherRouteTitle: string;
  canAccessSuccess: boolean;
  canAccessCheckout: boolean;
  lastCheckedVersion: string;
  isOpenedResetPasswordModal: boolean;
  listPaymentCode: PaymentConditionsProps[];

  setVersion: (value: string) => void;
  setLastSync: (value: string) => void;
  setRouteTitle: (value: string) => void;
  setPaymentCode: (value: number) => void;
  setFatherRouteTitle: (value: string) => void;
  setCanAccessSuccess: (value: boolean) => void;
  setCanAccessCheckout: (value: boolean) => void;
  setLastCheckedVersion: (value: string) => void;
  setIsOpenResetPasswordModal: (value: boolean) => void;
}

const ConfigsContex = createContext<HookProps>({} as HookProps);

const GlobalProvider: React.FC<any> = ({ children }) => {
  const { selectedClient: client } = useClient();
  const [version, setHookVersion] = useState(pacote.version);
  const [routeTitle, setHookRouteTitle] = useState("");
  const [paymentCode, setHookPaymentCode] = useState(14);
  const [fatherRouteTitle, setHookFatherTitle] = useState("");
  const [isOpenedResetPasswordModal, setIsOpenResetPasswordModal] = useState(false);
  const [lastCheckedVersion, setHookLastCheckedVersion] = useState(() => getOnStorage("lastCheckedVersion") || "");
  const [lastSync, setHookLastSync] = useState(() => {
    return getOnStorage("lastSync") || "Nenhuma atualização";
  });
  const [allPaymentCode, setHookListPaymentCode] = useState(
    [] as PaymentConditionsProps[]
  );

  const listPaymentCode = useMemo(() => {
    return (
      allPaymentCode?.sort((a, b) => a.cod_condicao - b.cod_condicao) || []
    );
  }, [allPaymentCode]);

  const [canAccessCheckout, setCanAccessCheckout] = useState(false);
  const [canAccessSuccess, setCanAccessSuccess] = useState(false);

  const setRouteTitle = useCallback(
    (title: string) => setHookRouteTitle(title),
    [setHookRouteTitle]
  );

  const setPaymentCode = useCallback(
    (value: number) => setHookPaymentCode(value),
    [setHookPaymentCode]
  );

  const setVersion = useCallback(
    (version: string) => setHookVersion(version),
    [setHookVersion]
  );

  const setLastSync = useCallback(
    (lastSync: string) => {
      setHookLastSync(lastSync);
      setOnStorage("lastSync", lastSync);
    },
    [setHookLastSync]
  );

  const setFatherRouteTitle = useCallback(
    (fatherTitle: string) => setHookFatherTitle(fatherTitle),
    [setHookFatherTitle]
  );

  const setLastCheckedVersion = useCallback(
    (lastCheckedVersion: string) => {
      setHookLastCheckedVersion(lastCheckedVersion);
      setOnStorage("lastCheckedVersion", lastCheckedVersion);
    },
    [setHookLastCheckedVersion]
  );

  useEffect(() => {
    if (!client?.COD_CLIENTE) return;
    setHookListPaymentCode(client?.COD_PAGAMENTO);
    setHookPaymentCode(client.COD_CONDICAO_PADRAO || client?.COD_PAGAMENTO?.[0]?.cod_condicao || 14);
  }, [client]);

  const resetAccess = () => {
    setCanAccessCheckout(false);
    setCanAccessSuccess(false);
  };

  const addZero = (numero: number) => (numero < 10 ? `0${numero}` : numero);

  const getDate = () => {
    const agora = new Date();
    const dia = addZero(agora.getDate());
    const mes = addZero(agora.getMonth() + 1); // Os meses em JavaScript são baseados em zero
    const ano = agora.getFullYear();
    const horas = addZero(agora.getHours());
    const minutos = addZero(agora.getMinutes());

    return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
  };

  return (
    <ConfigsContex.Provider
      value={{
        paymentCode,
        listPaymentCode,
        setPaymentCode,
        canAccessCheckout,
        canAccessSuccess,

        getDate,
        lastSync,
        setLastSync,

        version,
        setVersion,

        routeTitle,
        setRouteTitle,

        fatherRouteTitle,
        setFatherRouteTitle,

        setCanAccessCheckout,
        setCanAccessSuccess,
        resetAccess,

        isOpenedResetPasswordModal,
        setIsOpenResetPasswordModal,

        lastCheckedVersion,
        setLastCheckedVersion
      }}
    >
      {children}
    </ConfigsContex.Provider>
  );
};

const useGlobals = (): HookProps => {
  const ctx = useContext(ConfigsContex);

  if (!ctx) throw new Error("Erro ao usar hook Header");

  return ctx;
};

export { GlobalProvider, useGlobals };
