import * as S from './styles';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import indicadoresCampanhaSol from '@/assets/svgs/indicadores-campanha-sol.svg';
import { useDbFunctions } from '@/talons';

import { TopMixProps, IndicatorsFaixaProps } from '@/types';

const MAX_GRAPH_HEIGHT = 325;

type Faixa = {
  min: number;
  max: number;
};

type CampanhaFaixa = {
  DESCRICAO: string;
  NUMERO_FAIXA: number;
  INTERVALO_INFERIOR?: number;
  INTERVALO_SUPERIOR?: number;
};

type Campanha = {
  QTDE_CLIENTES_CARTEIRA: number;
  QTDE_CLIENTES: number;
  QTD_TOTAL_PRODUTO: number;
  QTDE_PRODUTOS: number;
};

type FaixasCombinadas = {
  faturamentoTotal: CampanhaFaixa;
  positivacaoClientesDigital?: CampanhaFaixa;
};

const calcularAlturaComFaixas = (
  valor: number,
  faixas: Faixa[],
  alturaMax: number,
  meta: number,
  alturaMinima: number = 20
): number => {
  if (!faixas || faixas.length === 0 || meta === 0) return alturaMinima;

  const alturaPorFaixa = (alturaMax / faixas.length);
  let alturaBase = 0;

  const porcentagemValor = (valor / meta) * 100;

  for (let i = 0; i < faixas.length; i++) {
    const faixa = faixas[i];
    const porcentagemMin = faixa.min;
    const porcentagemMax = faixa.max;

    if (porcentagemValor < porcentagemMin) {
      return Math.max(alturaBase, alturaMinima);
    }

    if (porcentagemValor <= porcentagemMax || i === faixas.length - 1) {
      // const proporcao = (porcentagemValor - porcentagemMin) / (porcentagemMax - porcentagemMin);
      const calc = parseFloat(Number(alturaMax * (porcentagemValor / 100)).toFixed(2)) - 44;
      return Math.max(calc, alturaMinima);
    }

    alturaBase += alturaPorFaixa;
  }

  return alturaMax;
};

type GraphColumnProps = {
  faixa: string;
  clientes: number;
  skus: number;
  isLastFaixa: boolean;
};

const GraphColumn: React.FC<GraphColumnProps> = ({ faixa, clientes, skus, isLastFaixa }) => (
  <S.GraphColumn>
    <S.TagLabel>
      <span>
        {faixa}
      </span>
    </S.TagLabel>
    <S.Data>
      <S.RowData>
        <S.DataBold>{clientes}</S.DataBold>
        <S.DataLabel>clientes</S.DataLabel>
      </S.RowData>
      <S.RowData>
        <S.DataBold>{skus}</S.DataBold>
        <S.DataLabel>sku's</S.DataLabel>
      </S.RowData>
    </S.Data>
  </S.GraphColumn>
);

type CampanhasProps = {
  indicadores: any;
};

const Campanhas: React.FC<CampanhasProps> = ({ indicadores }) => {
  const isLoaded = useRef(false);

  const [campanhaMix, setCampanhaMix] = useState<TopMixProps[]>([]);
  const [campanhaFaixa, setCampanhaFaixa] = useState<IndicatorsFaixaProps[]>([]);
  const [showDetails, setShowDetails] = useState<{ clientes: boolean; skus: boolean }>({ clientes: false, skus: false });

  const { getOnDB } = useDbFunctions();

  const groupsRange = useCallback((group?: any, type?: string) => {
    if (!group) return [];
    if (type === 'clientes') {
      if (group === '1' || group === '2') {
        return [
          { min: 30, max: 40 },
          { min: 41, max: 50 },
          { min: 51, max: 100 },
        ]
      } else {
        return [
          { min: 40, max: 50 },
          { min: 51, max: 60 },
          { min: 61, max: 100 },
        ]
      }
    } else {
      if (group === '1' || group === '2') {
        return [
          { min: 40, max: 50 },
          { min: 51, max: 60 },
          { min: 61, max: 100 },
        ]
      } else {
        return [
          { min: 50, max: 60 },
          { min: 61, max: 70 },
          { min: 71, max: 100 },
        ]
      }
    }
  }, []);

  const loadCampanhaMix = useCallback(async () => {
    const data = await getOnDB('campanha_mix');
    const faixa = await getOnDB('campanha_faixa');

    setCampanhaFaixa(faixa || []);
    setCampanhaMix(data || []);
  }, [setCampanhaFaixa, setCampanhaMix, getOnDB]);

  useEffect(() => {
    if (isLoaded.current) return;

    isLoaded.current = true;
    loadCampanhaMix();
  }, [loadCampanhaMix]);


  const metaClientes = campanhaMix[0]?.QTDE_CLIENTES_CARTEIRA || 1;
  const clientesRealizados = campanhaMix[0]?.QTDE_CLIENTES || 0;

  const skusTotal = campanhaMix[0]?.QTD_TOTAL_PRODUTO || 1;
  const skus = campanhaMix[0]?.QTDE_PRODUTOS || 0;

  const groupedCampanhaFaixa = campanhaFaixa.reduce((acc: Record<string, any>, item) => {
    const { DESCRICAO, NUMERO_FAIXA, ...rest } = item;
    if (!acc[DESCRICAO]) {
      acc[DESCRICAO] = { DESCRICAO, faixas: {} };
    }
    acc[DESCRICAO].faixas[NUMERO_FAIXA] = rest;
    return acc;
  }, {});

  const campanhasAgrupadas = Object.values(groupedCampanhaFaixa);

  const campanhaFaturamentoTotal = campanhasAgrupadas.find(
    (campanha) => campanha.DESCRICAO === "Faturamento Total"
  );

  const campanhaPositivacaoClientesDigital = campanhasAgrupadas.find(
    (campanha) => campanha.DESCRICAO === "Positivação de Clientes Digital"
  );

  const clientGroup = useMemo(() => {
    const clientGroup = campanhaPositivacaoClientesDigital
      ? Object.values(campanhaPositivacaoClientesDigital.faixas).find(
        (faixa: any) => (faixa as { GRUPO: string }).GRUPO
      )
      : undefined;

    const groupValue = clientGroup ? (clientGroup as { GRUPO: string }).GRUPO : undefined;

    return groupValue;
  }, [campanhaPositivacaoClientesDigital]);

  const faixasFaturamentoTotal: CampanhaFaixa[] = campanhaFaturamentoTotal ? Object.values(campanhaFaturamentoTotal.faixas) : [];
  const faixasPositivacaoClientesDigital: CampanhaFaixa[] = campanhaPositivacaoClientesDigital ? Object.values(campanhaPositivacaoClientesDigital.faixas) : [];


  const faixasCombinadas: FaixasCombinadas[] = faixasFaturamentoTotal.map((faixa: CampanhaFaixa, index: number) => ({
    faturamentoTotal: faixa,
    positivacaoClientesDigital: faixasPositivacaoClientesDigital[index] || undefined
  }));

  const faixaClientes: Faixa[] = groupsRange(clientGroup, 'clientes');
  const faixaSkus: Faixa[] = groupsRange(clientGroup, 'skus');

  const alturaClientes = calcularAlturaComFaixas(
    clientesRealizados,
    faixaClientes,
    MAX_GRAPH_HEIGHT,
    metaClientes
  );

  console.log('Altura Clientes', alturaClientes);

  const alturaSkus = calcularAlturaComFaixas(
    skus,
    faixaSkus,
    MAX_GRAPH_HEIGHT,
    skusTotal
  );

  const handleMarkerClick = (type: 'clientes' | 'skus') => {
    setShowDetails(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <S.Wrapper >
      <S.Header>
        <S.IconColumn>
          <img src={indicadoresCampanhaSol} alt="Indicadores de Campanha" />
        </S.IconColumn>
        <S.TitleColumn>
          Clientes
          <S.TitleValue>{metaClientes}</S.TitleValue>
        </S.TitleColumn>
        <S.TitleColumn>
          Skus
          <S.TitleValue>{skusTotal}</S.TitleValue>
        </S.TitleColumn>
      </S.Header>
      <S.Content>
        {faixasCombinadas?.reverse().map(({ faturamentoTotal, positivacaoClientesDigital }, index) => {
          const faixaAtual = faixasCombinadas.length - index;
          const isFaixa3 = faixaAtual === 3;

          const calcClientesPercent = Math.round(metaClientes * ((faixaClientes[faixaAtual - 1]?.min) / 100) || 0);
          const calcSkusPercent = Math.round(skusTotal * ((faixaSkus[faixaAtual - 1]?.min) / 100) || 0);

          return (
            <S.GraphRow key={index}>
              <GraphColumn
                faixa={`Faixa ${faixaAtual}`}
                clientes={calcClientesPercent}
                skus={calcSkusPercent}
                isLastFaixa={isFaixa3}
              />
              <S.GraphColumn />
              <S.GraphColumn />
            </S.GraphRow>
          );
        })}
        <S.BaseRow>
          <div></div>
          <S.GraphsWrapper>
            <S.GraphArrow $height={alturaClientes} />
            <S.GraphHolder>
              <S.Graph $height={alturaClientes} />
              <S.GraphMetrics $height={alturaClientes}>
                {showDetails.clientes && (
                  <S.Details>
                    <span>
                      {clientesRealizados}/<b>{metaClientes}</b>
                    </span>
                  </S.Details>
                )}
                <S.Marker $height={alturaClientes} onClick={() => handleMarkerClick('clientes')} />
              </S.GraphMetrics>
            </S.GraphHolder>
            <S.GraphArrow $height={alturaSkus} />
            <S.GraphHolder>
              <S.Graph $height={alturaSkus} />
              <S.GraphMetrics $height={alturaSkus}>
                {showDetails.skus && (
                  <S.Details>
                    <span>
                      {skus}/<b>{skusTotal}</b>
                    </span>
                  </S.Details>
                )}
                <S.Marker $height={alturaSkus} onClick={() => handleMarkerClick('skus')} />
              </S.GraphMetrics>
            </S.GraphHolder>
          </S.GraphsWrapper>
        </S.BaseRow>
      </S.Content>
    </S.Wrapper >
  );
};

export default Campanhas;
