import { colors } from "@/constants";
import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    padding: 0px 10px;
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr;

    background-color: ${colors.brand.yellow};

    width: 100%;
  
    height: 51px;
    gap: 0px;
    border-radius: 8px 8px 0px 0px;

`;

export const IconColumn = styled.div`
    display: flex;
    justify-content: center;
    
    img{
        position: relative;
        top: 12px;
    }
    
`;

export const TitleColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    text-transform: uppercase;
    color: ${colors.brand.grafite};
    font-size: 14px;
    font-weight: 600;

    height: 100%;
`;

export const TitleValue = styled.div`
    background-color: ${colors.brand.grafite};
    color: ${colors.brand.white};
    font-size: 14px;
    font-weight: 700;

    padding: 4px 8px 4px 8px;
    border-radius: 40px;
    position: relative;
    top: 5px;
`;

export const Content = styled.div`
    background-color: ${colors.brand.white};
    padding: 30px 12px 0 12px;
`;

export const GraphContainer = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr;

    background-color: red;
    position: relative;
`;

export const BaseRow = styled.div`
    width: 100%;
    height: 44px;
    display: grid;
    grid-template-columns: 36% 1fr;
`;

export const Row = styled.div` 
    width: 100%;
    height: 87px;
    border: solid 1px ${colors.brand.grafite};
    background-color: aliceblue;
`;

export const GraphsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;

    width: 100%;  
    position: relative;  
`;

export const GraphHolder = styled.div`
    height: 100%;
    position: relative; 

`;

export const Graph = styled.div<{ $height: number }>`
    width: 86px;
    height: ${props => props.$height}px;
    background-color: ${colors.gray.gray20};

    position: absolute;
    bottom: 0; 
    left: 50%; 
    transform: translateX(-50%);
    border-radius: 8px 8px 0 0;

    opacity: 0.5;

    display: flex;
    justify-content: center;
    z-index: 1;
`;

export const GraphColumn = styled.div`
    width: 100%;
    background-color: ${colors.brand.white};
    border-bottom: dashed 1px rgba(153, 153, 153, .5); 

    display: flex;
    align-items: center;

    position: relative;

    &:first-child{
        border-right: dashed 1px rgba(153, 153, 153, .5); 
    }
`;

export const GraphRow = styled.div`
    display: grid;
    grid-template-columns: 1.1fr 1fr 1fr;
    height: 87px;
    &:first-child{
        position: relative;
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid rgba(153, 153, 153, 0.5); // Cor da setinha
            position: absolute;
            top: -10px; // Ajuste para posicionar no topo
            left: 35.2%;
            transform: translateX(-50%);
        }
    }
`;

export const Data = styled.div`
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;

export const RowData = styled.div`
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: auto 1fr; */

    width: 100%;

    text-transform: uppercase;
`;

export const DataBold = styled.span`
    font-size: 14px;
    font-weight : 700;
    line-height: 16.41px;
`;

export const DataLabel = styled.span`
   font-size: 14px;
   font-weight : 400;
   color: ${colors.gray.gray40};
   line-height: 16.41px;
`;

export const TagLabel = styled.div`
    padding: 8px 4px;
    width: auto;
    background-color: ${colors.brand.yellow};
    border-radius: 6px;

    font-size: 12px;
    text-transform: uppercase;
    color: ${colors.brand.grafite};
    font-weight: 700;

    /* position: absolute;
    bottom: -10px;
    left: 0; */
    z-index: 99;

    span{
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
`;

export const GraphMetrics = styled.div<{ $height?: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: auto;

    position: absolute;
    bottom: calc(${props => props.$height}px - 5px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    z-index: 2;
`;

export const Details = styled.div<{ $height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    padding: 12px 16px;
    box-shadow: 0px 4px 10px 0px #37322D40;
    border-radius: 8px;
    position: relative;
    margin-bottom: 5px;

    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
    color: ${colors.brand.grafite};
    background-color: ${colors.brand.white};
    z-index: 999999;

    b{
        font-size: 14px;
        font-weight: 700;
        line-height: 19.6px;
    }

    ::after {
        content: '';
        position: absolute;
        top: 100%; 
        left: 50%;
        transform: translateX(-50%); 
        border-width: 6px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        z-index: 1; 
    }
`;

export const Marker = styled.div<{ $height: number }>`
    width: 12px;
    height: 12px;
    background-color: ${colors.brand.grafite};
    border-radius: 50%;
    border: solid 4px ${colors.brand.white};
    box-shadow: 0px 2px 12px 0px #00000040;   
`;

export const GraphArrow = styled.div<{ $height?: number }>`
    width: 110%;
    height: ${props => props.$height}px;
    background-color: transparent;
    border-top: 1px dashed ${colors.brand.grafite};
    position: absolute;
    bottom: 0; 
    right: 0;

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 8px solid ${colors.brand.grafite};
        position: absolute;

        transform: translateY(-50%);
    }
`;
