import { useDbFunctions } from "@/talons";
import { ClientProps, ClientAddressProps, CampanhaProdutosProps, CampanhaClientesProps } from "@/types";
import { getOnStorage, removeFromStorage, setOnStorage } from "@/shared";
import { useState, useEffect, useContext, useCallback, createContext, useMemo } from "react";

interface HookProps {
  janelaEntregaData: any;
  allClients: ClientProps[];
  selectedClient: ClientProps;
  campanhaProdutos: CampanhaProdutosProps;
  campanhaClientes: CampanhaClientesProps;
  selectedClientAddress: ClientAddressProps;
  logoutClient: () => void;
  calculateDeliveryDate: () => string;
  setJanelaEntregaData: (data: any) => void;
  setAllClients: (clients: ClientProps[]) => void;
  setSelectedClient: (client: ClientProps) => void;
  setCampanhaClientes: (campanha: CampanhaClientesProps) => void;
  setCampanhaProdutos: (campanha: CampanhaProdutosProps) => void;
  setPositivacao: (mode: 'produto' | 'cliente', ids: number[]) => void;
}

const ConfigsContex = createContext<HookProps>({} as HookProps);

const reduceLsit = (list?: number[]) => list?.reduce((acc, p) => ({ [p]: p, ...acc }), {}) || {};

const ClientProvider: React.FC<any> = ({ children }) => {
  const { getOnDB, setOnDB } = useDbFunctions();
  const [isLoaded, setIsLoaded] = useState(false);

  const client = getOnStorage("selected_client");
  const [janelaEntrega, setJanelaEntrega] = useState<any>(null);
  const [allClientsHooks, setAllClients] = useState([] as ClientProps[]);
  const [janelaEntregaData, setJanelaEntregaData] = useState<any>(null);
  const [campanhaProdutos, setHookCampanhaProdutos] = useState({} as CampanhaProdutosProps);
  const [selectedClient, setHookSelectedClient] = useState({} as ClientProps);
  const [campanhaClientes, setHookCampanhaClientes] = useState({} as CampanhaClientesProps);
  const [selectedClientAddress, setSelectedClienteAddress] = useState({} as ClientAddressProps);

  // Função para buscar janelaEntrega
  const fetchJanelaEntrega = useCallback(async () => {
    try {
      const result = await getOnDB("janelaEntrega");
      setJanelaEntrega(result);  // Armazena o resultado no estado
    } catch (error) {
      console.error("Erro ao buscar janelaEntrega:", error);
    }
  }, [getOnDB]);

  const calculateDeliveryDate = useCallback(() => {
    const cod_rota = client?.COD_ROTA;
    const days = {
      1: 'Domingo',
      2: 'Segunda-feira',
      3: 'Terça-feira',
      4: 'Quarta-feira',
      5: 'Quinta-feira',
      6: 'Sexta-feira',
      7: 'Sábado',
    };

    const janelasEntrega = janelaEntrega?.filter((row: any) => row.COD_ROTA === cod_rota);
    if (!janelasEntrega?.length) {
      return 'Nenhuma janela de entrega disponível no momento.'
    }

    const now = new Date();
    const weekDayNow = now.getDay();
    const hour = now.getHours();

    const weekDay = weekDayNow === 0 ? 7 : weekDayNow;

    for (let janela of janelasEntrega) {
      const initialDay = janela.DIA_INT_INICIAL;
      const initialHour = janela.HORA_INT_INICIAL;
      const finalDay = janela.DIA_INT_FINAL;
      const finalHour = janela.HORA_INT_FINAL;

      const isWithinRange =
        (weekDay > initialDay || (weekDay === initialDay && hour >= initialHour)) &&
        (weekDay < finalDay || (weekDay === finalDay && hour < finalHour));

      if (isWithinRange) {
        // console.log(`Data prevista de entrega: ${janela.DATA_PREVISTA_ENTREGA} (${days[initialDay as keyof typeof days]})`)
        return `Data prevista de entrega: ${janela.DATA_PREVISTA_ENTREGA} (${days[initialDay as keyof typeof days]})`
      }
    }
    return 'Nenhuma janela de entrega disponível no momento.';
  }, [client, janelaEntrega]);

  const setSelectedClient = useCallback(
    (newClient: ClientProps) => {
      setOnStorage("selected_client", newClient);
      setHookSelectedClient(newClient);
    },
    [setHookSelectedClient]
  );

  const logoutClient = useCallback(() => {
    removeFromStorage("selected_client");
    setHookSelectedClient({} as ClientProps);
    setSelectedClienteAddress({} as ClientAddressProps);
    localStorage.setItem("@PWA_RCA:selected_client", "");
    setIsLoaded(false);
  }, [setHookSelectedClient]);

  const setCampanhaProdutos = useCallback((campanha: CampanhaProdutosProps) => {
    if (!campanha) return

    if(campanha?.ativo?.produto) campanha.ativo.ids = reduceLsit(campanha?.ativo?.produto)
    if(campanha?.inativo?.produto) campanha.inativo.ids = reduceLsit(campanha?.inativo?.produto)
    
    setHookCampanhaProdutos(campanha);
  }, []);

  const setCampanhaClientes = useCallback((campanha: CampanhaClientesProps) => {
    console.log(campanha);
    if (!campanha) return

    
    if(campanha?.ativo?.clientes) campanha.ativo.ids = reduceLsit(campanha?.ativo?.clientes)
    if(campanha?.inativo?.cliente) campanha.inativo.ids = reduceLsit(campanha?.inativo?.cliente)
    
    setHookCampanhaClientes(campanha);
  }, []);

  const setPositivacao = useCallback((mode: 'produto' | 'cliente', ids: number[]) => {
    // const isProduct = mode === 'produto' 
    // if (isProduct) {
    //   const campanha = {...campanhaProdutos};
    //   campanha.inativo.produto = campanha.inativo.produto.filter((p: number) => !ids.includes(p))
    //   campanha.ativo.produto = [...campanha.ativo.produto, ...ids]
    
    //   setCampanhaProdutos(campanha)
    //   setOnDB(campanha, 'campanhaProduto')
    //   return
    // }
    
    // const campanha = {...campanhaClientes};
    // campanha.inativo.cliente = campanha.inativo.cliente.filter((p: number) => !ids.includes(p))
    // campanha.ativo.clientes = [...campanha.ativo.clientes, ...ids]
    
    // setCampanhaClientes(campanha)
    // setOnDB(campanha, 'campanhaCliente')
    // }, [campanhaProdutos, campanhaClientes, setCampanhaProdutos, setCampanhaClientes, setOnDB]);
  }, []);

  const loadStorageDatas = useCallback(async (client: any) => {
    const clientAddress = {
      street: client.ENDERECO,
      address_number: client.NUMERO,
      region: client.BAIRRO,
      state: client.ESTADO,
      city: client.CIDADE,
      name: client.NM_CLIENTE,
      cep: client.CEP,
      telephone: client.NR_TELEFONE,
    };
    setHookSelectedClient(client);
    setSelectedClienteAddress(clientAddress);

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!janelaEntrega && client) {
      fetchJanelaEntrega();
    }
  }, [janelaEntrega, client, fetchJanelaEntrega]);

  useEffect(() => {
    if (!isLoaded && client) {
      loadStorageDatas(client);
    }
  }, [isLoaded, client, loadStorageDatas]);

  useEffect(() => {
    const getCampanhas = async () => {
      const campanhaProdutos = await getOnDB('campanhaProduto')
      setCampanhaProdutos(campanhaProdutos)
      const campanhaClientes = await getOnDB('campanhaCliente')
      setCampanhaClientes(campanhaClientes)
    }

    getCampanhas()
  }, []);

  const allClients = useMemo(() => {
    return allClientsHooks && allClientsHooks.length ? allClientsHooks : []
  }, [allClientsHooks])

  return (
    <ConfigsContex.Provider
      value={{
        campanhaProdutos,
        campanhaClientes,
        allClients,
        selectedClient,
        selectedClientAddress,
        janelaEntregaData,
        
        logoutClient,
        setAllClients,
        setPositivacao,
        setSelectedClient,
        setCampanhaProdutos,
        setCampanhaClientes,
        setJanelaEntregaData,
        calculateDeliveryDate,
      }}
    >
      {children}
    </ConfigsContex.Provider>
  );
};

const useClient = (): HookProps => {
  const ctx = useContext(ConfigsContex);

  if (!ctx) throw new Error("Erro ao usar hook Header");

  return ctx;
};

export { ClientProvider, useClient };
