import { getOnStorage, setOnStorage } from '@/shared';
import { useCallback, useMemo, useState } from 'react';
import { ItemsProps, OrderItemsProps } from '../orderStatusPage/useOrderStatusPage';
import { useClient, useFetchAxios, useNetworkStatusContext, useUserContext } from '@/hooks';

interface HookProps {
  order?: OrderItemsProps;
  closeModal?: () => void;
  companyData?: {
    clientName: string;
    clientRefNumber: string;
    clientCnpj: string;
  }
}

function useOrderStatusModal(props = {} as HookProps) {
  const { order, companyData, closeModal } = props;

  const { axiosCtx } = useFetchAxios();
  const { customer } = useUserContext();
  const { setPositivacao, selectedClient } = useClient();
  const { connectivityStatus } = useNetworkStatusContext();

  const [loading, setLoading] = useState(false);

  const generateOrderNumber = (seqpessoa: number) => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const formattedDate = `${day}${month}${year}`;

    const formattedHour = String(now.getHours()).padStart(2, "0");
    const formattedMinute = String(now.getMinutes()).padStart(2, "0");
    const formattedSecond = String(now.getSeconds()).padStart(2, "0");

    return `${seqpessoa +
      formattedDate +
      formattedHour +
      formattedMinute +
      formattedSecond
      }`;
  };

  const orderPayload = useMemo(() => {
    if (order) {
      return {
       company: {
        Cod_rca: customer?.codPessoa?.toString(),
        cnpj: companyData?.clientCnpj,
        name: companyData?.clientName,
        id: companyData?.clientRefNumber,
       },
       orders: {
        actions_pending: order?.actionsPending?.map((item) => ({
          cod_segmento: item?.cod_segmento,
          sku: item?.sku,
          product: item?.product,
          quantity: item?.quantity,
          quantity_multiplo: item?.quantityMultiplo,
          percentual_flex: item?.percentualFlex || "0",
          integration_price: item?.integrationPrice,
          integration_price_flex: item?.integrationPriceFlex || 0,
          price: item?.price,
          unit_price: item?.unitPrice,
          unit_price_per_item: item?.unitPricePerItem,
          st_price: item?.stPrice,
          st_price_packaging: item?.stPricePackaging,
          packaging: item?.packaging,
          reason: "",
          status: "",
        })),
        order_date: order?.orderDate,
        order_number: order?.orderNumber,
        order_number_magento: order?.orderNumberMagento,
        erp_order_number: order?.erpNumber,
        origin: "BateForte_web_mobile_rca",
        items: order?.actionsPending?.length,
        total: order?.total,
        payment_terms: order?.paymentTerms,
        status: "",
        reason: "",
       },
      }
    }
  }, [order, customer?.codPessoa, companyData?.clientCnpj, companyData?.clientName, companyData?.clientRefNumber]);

  const handleReprocessOrder = useCallback(async () => {
    if (!orderPayload) {
      console.log("Order payload is not ready");
      return;
    }

    if (connectivityStatus === "online") {
      try {
        setLoading(true);
        const orderFromStorage = await getOnStorage("offline_orders");
        const synched = await getOnStorage("synched_orders");
        let synchedOrders = [];

        if (synched && synched !== undefined && synched !== "undefined" && synched?.length) {
          synchedOrders = synched;
        }

        const orderIndex = orderFromStorage?.findIndex((i: any) => i?.orders?.order_number === orderPayload?.orders?.order_number && i?.company?.id === orderPayload?.company?.id);

        if (orderIndex > -1) {
          orderFromStorage.splice(orderIndex, 1);
          synchedOrders?.push({
            ...orderPayload,
            orders: {
              ...orderPayload?.orders,
              status: "processando",
            }
          })
          setOnStorage("offline_orders", orderFromStorage);
          setOnStorage("synched_orders", synchedOrders);
        }

        const response = await axiosCtx
          .post("/integra-pedido", orderPayload)
          .then(res => res?.data?.message)
          .catch((error) => `Error, ${error}`);

        if (response) {
          const skus = orderPayload?.orders?.actions_pending?.map(i => Number(i.sku)) || [];
          setPositivacao('produto', skus);
          setPositivacao('cliente', [Number(selectedClient?.COD_CLIENTE)]);
          await new Promise((resolve) => setTimeout(() => resolve(true), 1000))
          closeModal && closeModal()
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [orderPayload, connectivityStatus, axiosCtx, selectedClient, closeModal, setPositivacao]);

  const handleUpdateOrder = useCallback(({ pendingActions }: { pendingActions: ItemsProps[] }) => {
    const orderNumber = generateOrderNumber(customer?.codPessoa);
    const payload = {
      ...orderPayload,
      orders: {
        ...orderPayload?.orders,
        actions_pending: pendingActions?.map((item) => ({
          sku: item?.sku,
          product: item?.product,
          price: item?.price,
          unit_price: item?.unitPrice,
          unit_price_per_item: item?.unitPricePerItem,
          st_price: item?.stPrice,
          st_price_packaging: item?.stPricePackaging,
          integration_price: item?.integrationPrice,
          integration_price_flex: item?.integrationPriceFlex,
          quantity: item?.quantity,
          quantity_multiplo: item?.quantityMultiplo,
          percentual_flex: item?.percentualFlex,
          packaging: item?.packaging,
          status: "reprocessar",
          reason: "[ Apenas 2 em estoque ]",
        })),
        order_number: orderNumber,
        status: "reprocessar",
      }
    }
    const synched = getOnStorage("synched_orders");
    let synchedOrders = [];

    if (synched && synched !== undefined && synched !== "undefined" && synched?.length) {
      synchedOrders = synched;
    }

    synchedOrders?.push({ ...payload });

  }, [orderPayload, customer?.codPessoa]);

  return {
    loading,
    handleReprocessOrder,
    handleUpdateOrder,
  }
}

export default useOrderStatusModal