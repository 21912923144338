import * as S from './styles';
import { useClient, useGlobals } from '@/hooks';
import { getOnStorage } from '@/shared';
import { useDbFunctions } from '@/talons';
import { useNavigate } from 'react-router-dom';
import { ECOMMERCE } from '@/constants/systemRoutes';
import { ClientProps, TabCodes, TabParams } from '@/types';
import { Header, ListCompany, TabSlider, ToolTip } from '@/components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { copy } from '@/shared/commonFunctions';

const MyClientsView = () => {
  const isLoaded = useRef(false);
  const navigation = useNavigate();
  const { getOnDB } = useDbFunctions();
  const { setPaymentCode } = useGlobals();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState('');
  const { setSelectedClient } = useClient();
  const [openSearch, setOpenSearch] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [clients, setClients] = useState([] as ClientProps[]);
  const [clientsDetail, setClientsDetail] = useState([] as any);
  const [selectedTab, setSelectedTab] = useState('all' as TabCodes);
  const [selectedClient, setLocalClient] = useState({} as ClientProps | undefined);

  const goTo = () => {
    if (!selectedClient) return;

    setPaymentCode(selectedClient?.COD_CONDICAO_PADRAO || selectedClient?.COD_PAGAMENTO?.[0]?.cod_condicao || 14);
    setSelectedClient(selectedClient);
    navigation(ECOMMERCE.link);
  };

  const handleDisabled = useCallback(async () => {
    const dependencies = await Promise.all([
      getOnDB('produtos'),
      getOnDB('categorias'),
      getOnDB('estoque'),
      getOnDB('marcas'),
      getOnDB('percentualFornecedor'),
      getOnDB('precos'),
      getOnDB('substituicaotributaria'),
    ]);

    const isFilled = dependencies.reduce((a, i) => (a ? !!i?.length : a), true);
    setShowTooltip(!isFilled);
  }, [getOnDB]);

  const loadClients = useCallback(async () => {
    const list = await getOnDB('clientes');
    const listDetails = await getOnDB('campanhaCliente');
    const currClient = getOnStorage('selected_client');
    if (currClient) setLocalClient(currClient);
    setClients(list || []);
    setClientsDetail(listDetails || []);
  }, [getOnDB]);

  useEffect(() => {
    if (isLoaded.current) return;
    isLoaded.current = true;
    loadClients();
    handleDisabled();
  }, [loadClients, handleDisabled]);

  const listClients = useMemo((): ClientProps[] => {
    if (filter) {
      return clients.filter((c) => {
        const toFilter = copy(c);

        delete toFilter.progress1;
        delete toFilter.progress2;
        delete toFilter.progress3;
        delete toFilter.progress4;
        delete toFilter.COD_PAGAMENTO;

        return Object.values(toFilter).toString().toLowerCase().includes(filter.toLocaleLowerCase());
      });
    }

    if (selectedTab === 'all') return clients;

    if (selectedTab === 'noPurchases') {
      return clients.filter((c) => c.daysWithoutPurchase >= 30);
    }

    if (selectedTab === 'suspended') {
      return clients.filter((c) => c.suspended);
    }

    if (selectedTab === 'blocked') {
      return clients.filter((c) => c.ST_COMPRA === 'I');
    }

    if (selectedTab === 'active') {
      return clients.filter((c) => c.ST_COMPRA != 'I');
    }

    if (selectedTab === 'notApproved') {
      return clients.filter((c) => {
        const inativos = clientsDetail.inativo.cliente;
        return inativos?.includes(c.COD_CLIENTE);
      });
    }

    if (selectedTab === 'approved') {
      return clients.filter((c) => {
        const ativos = clientsDetail.ativo.clientes;
        return ativos?.includes(c.COD_CLIENTE);
      });
    }

    return clients.filter((c) => c.notifications);
  }, [filter, clients, clientsDetail, selectedTab]);

  useEffect(() => {
    if (openSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [openSearch]);

  const listTabs: TabParams[] = [
    {
      disabled: false,
      code: 'all',
      title: 'Todos',
      notification: clients?.length,
    },
    {
      disabled: false,
      code: "active",
      title: "Ativos",
      notification: clients.filter((c) => c.ST_COMPRA != 'I').length,
    },
    {
      disabled: false,
      code: "blocked",
      title: "Bloqueados",
      notification: clients.filter((c) => c.ST_COMPRA === 'I').length,
    },
    {
      disabled: !clients.filter((c) => c.daysWithoutPurchase).length,
      code: "noPurchases",
      title: "Sem comprar",
      notification: clients.filter((c) => c.daysWithoutPurchase).length || 0,
    },
    {
      disabled: false,
      code: "approved",
      title: "Positivou na Campanha",
      notification: clients.filter((c) => {
        const ativos = clientsDetail?.ativo.clientes;
        return ativos?.includes(c.COD_CLIENTE);
      }).length,
    },
    {
      disabled: false,
      code: "notApproved",
      title: "Não positivou na campanha",
      notification: clients.filter((c) => {
        const inativos = clientsDetail?.inativo.cliente;
        return inativos?.includes(c.COD_CLIENTE);
      }).length,

    },
    // {
    //   disabled: true,
    //   code: "titles",
    //   title: "Títulos",
    //   notification: clients.filter((c) => c.notifications).length,
    // }
  ];

  return (
    <>
      <S.Container>
        {!openSearch ? (
          <Header
            stickChild={<TabSlider listTabs={listTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
            rightIcon={
              <S.SearchContent>
                <S.SearchIcon size={20} data-test="my-clients-button-search" onClick={() => setOpenSearch(true)} />
              </S.SearchContent>
            }
          />
        ) : (
          <S.SearchHeader>
            <S.SearchInputWrapper>
              <S.SearchIcon size={20} data-test="my-clients-input-search" $isgray />
              <S.SearchInput
                ref={searchInputRef}
                type="text"
                placeholder="Busque por cliente..."
                onChange={(event: any) => setFilter(event.target.value.trim())}
              />
            </S.SearchInputWrapper>
            <S.CloseSearch onClick={() => setOpenSearch(false)}>Cancelar</S.CloseSearch>
          </S.SearchHeader>
        )}

        <S.Content>
          <S.Background />
          <S.ContetListCompany>
            <ListCompany clients={listClients} selectedClient={selectedClient} setLocalClient={setLocalClient} />
          </S.ContetListCompany>

          {!!selectedClient?.COD_CLIENTE && (
            <S.BottomContent>
              <ToolTip
                direction="top"
                show={showTooltip}
                displayMode="default"
                text="Por favor, sincronize a aplicação para liberar o acesso."
              >
                <S.Button disabled={showTooltip} data-test="my-clients-enter-client" onClick={goTo}>
                  Entrar com esse cliente
                </S.Button>
              </ToolTip>
            </S.BottomContent>
          )}
        </S.Content>
      </S.Container>
    </>
  );
};

export default MyClientsView;
