import Header from './header';
import * as S from './styles';
import MenuList from './list';
import { InfosVersion } from '@/components';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { useDbFunctions } from "@/talons";
import { useState, useCallback, useEffect, useRef } from 'react';
import Metricas from "./metricas";
import { IndicatorsProps } from "@/types";
// import FastLinks from "./fast-links";

const DashboardPage = () => {
  const [isMetricasVisible, setIsMetricasVisible] = useState(false);
  const [isCampanhasVisible, setIsCampanhasVisible] = useState(false);
  const { getOnDB } = useDbFunctions();
  const isLoaded = useRef(false);

  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null);
  const [indicadores, setIndicators] = useState([] as IndicatorsProps[]);
  const [campanha, setCampanha] = useState([] as any[]);

  const toggleMetricasVisibility = () => {
    setIsMetricasVisible(!isMetricasVisible);
  };

  const toggleCampanhasVisibility = () => {
    setIsCampanhasVisible(!isCampanhasVisible);
  };

  const loadDataset = useCallback(async () => {
    const list = await getOnDB('indicadores');
    const campanhalist = await getOnDB('campanha');

    setIndicators(list || []);
    setCampanha(campanhalist || []);
  }, [getOnDB]);

  useEffect(() => {
    if (isLoaded.current) return

    isLoaded.current = true
    loadDataset()
  }, [loadDataset]);

  const handleTooltipToggle = (id: string) => {
    setVisibleTooltip(visibleTooltip === id ? null : id);
  };

  const indicadoresDisabled = indicadores?.length === 0;
  const campanhasDisabled = campanha?.length === 0;

  return (
    <S.Container className="dashboards">
      <Header />
      <S.Title onClick={() => { toggleCampanhasVisibility(); handleTooltipToggle('campanhas'); }} disabled={campanhasDisabled}>
        Campanhas
        {!campanhasDisabled && isCampanhasVisible ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
        {campanhasDisabled && visibleTooltip === 'campanhas' &&
          <S.Tooltip isVisible={visibleTooltip === 'campanhas'}>
            Em breve.
          </S.Tooltip>}
      </S.Title>
      {!campanhasDisabled && isCampanhasVisible && (
       <Metricas isVisible={isCampanhasVisible} indicadores={campanha} campanha={true} />
      )}
      {/* <FastLinks /> */}
      <S.Title onClick={() => { toggleMetricasVisibility(); handleTooltipToggle('indicadores'); }} disabled={indicadoresDisabled}>
        Meus indicadores
        {!indicadoresDisabled && isMetricasVisible ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
        {indicadoresDisabled && visibleTooltip === 'indicadores' &&
          <S.Tooltip isVisible={visibleTooltip === 'indicadores'}>
            Em breve.
          </S.Tooltip>}
      </S.Title>
      {!indicadoresDisabled && (
        <Metricas isVisible={isMetricasVisible} indicadores={indicadores} campanha={false} />
      )}
      <S.Title>Menus</S.Title>
      <MenuList />
      <InfosVersion />
    </S.Container>
  );
};

export default DashboardPage;
