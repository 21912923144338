import { useClient } from '@/hooks';

type Props = {
  codProduto?: number;
  codCliente?: number;
  mode?: 'produto' | 'cliente';
};

const SeloCampanha = ({ codProduto, codCliente, mode = 'produto' }: Props) => {
  const { campanhaProdutos, campanhaClientes } = useClient();

  if ((!campanhaProdutos && !campanhaClientes) || (!codProduto && !codCliente)) return null;

  const cod = (codProduto ?? codCliente) as number;
  const campanha = mode === 'produto' ? campanhaProdutos : campanhaClientes;
  
  if (campanha?.ativo?.ids?.[cod]) return <img style={{ marginTop: -7 }} src={campanha.ativo.selo} alt={`Selo promocional ativo ${cod}`} />
  if (campanha?.inativo?.ids?.[cod]) return <img style={{ marginTop: -7 }} src={campanha.inativo.selo} alt={`Selo promocional inativo ${cod}`} />
};

export default SeloCampanha;